import siteinfo from './siteinfo'
import QRCode from 'qrcode'
const OSS = require('ali-oss')

// 对象有无 指定 键
export function hasProperty(obj, key) {
  return key in obj
}

// 对象相同 键 赋值
export function objectKeys(obj1, obj2) {
  Object.keys(obj1).forEach((key) => {
    if (hasProperty(obj2, key)) return (obj2[key] = obj1[key])
  })
}

// 上传oss
export function ossUpload(path, file) {
  return new Promise((resolve) => {
    let client = new OSS(siteinfo.oss)
    var response = client.put(path, file)
    response.then((res) => {
      resolve(res.url)
    })
  })
}

// 下载图片
export function downloadIamge(imgsrc, name) {
  //下载图片地址和图片名
  var image = new Image()
  // 解决跨域 Canvas 污染问题
  image.setAttribute('crossOrigin', 'anonymous')
  image.onload = function () {
    var canvas = document.createElement('canvas')
    canvas.width = image.width
    canvas.height = image.height
    var context = canvas.getContext('2d')
    context.drawImage(image, 0, 0, image.width, image.height)
    var url = canvas.toDataURL('image/png') //得到图片的base64编码数据'
    var a = document.createElement('a') // 生成一个a元素
    var event = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    }) // 创建一个单击事件
    a.download = name || 'photo' // 设置图片名称
    a.href = url // 将生成的URL设置为a.href属性
    a.dispatchEvent(event) // 触发a的单击事件
  }
  image.src = imgsrc
}

// 生成微信支付二维码
export function getQRcode(text) {
  return new Promise((resolve, reject) => {
    let opts = {
      errorCorrectionLevel: 'L', //容错级别
      type: 'image/png', //生成的二维码类型
      quality: 0.3, //二维码质量
      margin: 5, //二维码留白边距
      width: 128, //宽
      height: 128, //高
      text, //二维码内容
      color: {
        dark: '', //前景色
        light: '#fff' //背景色
      }
    }
    QRCode.toDataURL(text, opts, (err, url) => {
      if (err) return reject(err)
      //将生成的二维码路径复制给data的QRImgUrl
      return resolve(url)
    })
  })
}

// 对象有无空值
export function isObjEmpty(obj) {
  return Boolean(Object.values(obj).filter((item) => item === '' || item === null).length)
}
